<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <h2 class="title">
        <div class="title_icon"></div>
        {{ isEdit ? "修改" : "添加" }}角色信息
      </h2>
      <!-- <div class='roleName' v-if='isEdit'>当前编辑角色：{{managerRoleName}}</div> -->
      <div class="content-wrapper">
        <el-form
          ref="form"
          label-position="right"
          :model="formInline"
          :rules="rules"
          label-width="100px"
          style="width: 500px"
        >
          <el-form-item :label="$t('searchModule.Role_Name')" prop="bossManagerRoleName">
            <el-input v-model="formInline.bossManagerRoleName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.remarks')">
            <el-input v-model="formInline.description"></el-input>
          </el-form-item>
          <el-form-item :label="$t('searchModule.Department_to')" prop="deptId">
            <el-cascader
              ref="deptIdCascader"
              :options="treeData"
              expand-trigger="hover"
              filterable
              clearable
              :show-all-levels="false"
              v-model="formInline.deptIdArr"
              :props="defaultPropsCascader"
              change-on-select
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
        </el-form>
        <div class="all" v-show="formInline.deptId">
          <!-- <el-button @click="setAuthority(true)">全部选择</el-button>
          <el-button @click="setAuthority(false)">全部取消</el-button>-->
          <span class="titleLeft" name>权限分配</span>
          <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        </div>
        <el-tree
          ref="tree"
          :empty-text="emptyText"
          :data="sourceData"
          @check="nodeClickHandle"
          :props="treeProp"
          show-checkbox
          accordion
          node-key="code"
        />
        <!-- <tree-con ref='treeComponent' :sourceData='sourceData' :selected='selected'></tree-con> -->
        <div style="margin-top: 20px; text-align: center">
          <el-button type="primary" @click="save">{{ $t('button.preservation') }}</el-button>
          <el-button plain @click="$router.go(-1)">取消</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//   import treeCon from '../../../components/tree/'
import TitleModule from "../../../components/titleModule/titleModule";
export default {
  name: "hello",
  data() {
    let validateName = (rule, value, callback) => {
      if (this.isEdit && value == this.$route.query.bossManagerRoleName) {
        return callback();
      } else if (!/^[a-zA-Z0-9\u4E00-\u9FA5]{2,50}$/.test(value)) {
        callback(new Error("角色名称必须为数字字母或汉字且长度在2-50之间"));
      } else {
        this.$axios
          .get("/acb/2.0/bossManagerRole/checkRoleName/" + this.formInline.bossManagerRoleName)
          .then((res) => {
            if (res.value) {
              callback();
            } else {
              callback(new Error("角色名称已经存在"));
            }
          });
      }
    };
    return {
      deptTree: [], // 全集所有部门
      treeData: [],
      emptyText: "",
      defaultPropsCascader: {
        children: "childNodes",
        label: "deptName",
        value: "deptId",
        // checkStrictly: true,
      },
      elecRoleId: "",
      checkAll: false,
      isEdit: false,
      checkArr: [],
      treeList: [],
      sourceData: [],
      queryArr: [],
      treeProp: {
        children: "children",
        label: "desc",
      },
      selected: [],
      resourceList: [],
      formInline: {
        bossManagerRoleName: "",
        description: "",
        resourceIds: "",
        elecResourceIds: "",
        deptId: "",
        deptIdArr: [],
      },
      rules: {
        deptId: [
          {
            required: true,
            message: "请选择部门",
            trigger: "blur",
          },
        ],
        bossManagerRoleName: [
          {
            required: true,
            message: "请输入角色名称",
            trigger: "blur",
          },
          { validator: validateName, trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    getDeptTree() {
      this.$axios.get("/acb/2.0/dept/getDeptTree").then((res) => {
        this.deptTree = [res.value];
        if (this.$route.query.bossManagerRoleId) {
          this.isEdit = true;
          this.getRoleDetail();
        }
      });
    },
    // 获取所属部门
    getByCurrentUser() {
      this.$axios.get("/acb/2.0/dept/getByCurrentUser").then((res) => {
        // res.value.childNodes.forEach(ele => {
        //   if (ele.childNodes) {
        //     delete ele.childNodes
        //   }
        // });
        this.treeData = [];
        this.treeData = [res.value];
      });
    },
    handleChange(value) {
      if (value.length) {
        console.log(value, "hjjfdajj");
        this.formInline.deptIdArr = value;
        this.formInline.deptId = value[value.length - 1];
        // if (value.length > 2) {
        //   this.getResource(value[1]);
        // } else {
        //   this.getResource(this.formInline.deptId);
        // }
        setTimeout(() => {
          this.returnLevel(this.formInline.deptId, this.deptTree);
        }, 150);
      }
    },
    // renderContent(h, { node, data, store }) {
    // 	return (
    //      <span class="custom-tree-node">
    //         <span>{node.label}</span>
    //         <span>
    //           <el-button size="mini" type="text" on-click={ () => this.append(data) }>测试</el-button>
    //         </span>
    //       </span>
    //   )
    // },
    setAuthority(isChecked) {
      let arr = [];
      if (isChecked) {
        this.sourceData.forEach((v) => {
          arr.push(v.code);
        });
      } else {
        arr = this.$refs.tree.getCheckedKeys();
      }
      arr.forEach((v) => {
        this.$nextTick(() => {
          this.$refs.tree.setChecked(v, isChecked, true);
        });
      });
    },
    handleCheckAllChange() {
      let arr = [];
      if (this.checkAll) {
        this.sourceData.forEach((v) => {
          v.children.forEach((element) => {
            arr.push(element.code);
          });
        });
      } else {
        arr = this.$refs.tree.getCheckedKeys();
      }
      arr.forEach((v) => {
        this.$nextTick(() => {
          this.$refs.tree.setChecked(v, this.checkAll, true);
        });
      });
    },
    nodeClickHandle(data, arg) {
      if (data.function && data.function == "query") {
        let curNode = this.$refs.tree.getNode(data.code);

        if (!curNode.checked) {
          let parentNode = curNode.parent;
          this.$refs.tree.setChecked(parentNode.data, false, true);
        }
        let parentNodeT = curNode.parent.parent;
        let childNodesT = parentNodeT.childNodes;
        let queryNodeT = childNodesT.find((v) => {
          return v.data.function == "query";
        });
        if (!queryNodeT.checked) {
          this.$refs.tree.setChecked(queryNodeT.data, true, false);
        }
      } else if (data.function && data.function != "query") {
        let curNode = this.$refs.tree.getNode(data.code);
        if (curNode.checked) {
          let parentNode = curNode.parent;
          let childNodes = parentNode.childNodes;
          let queryNode = childNodes.find((v) => {
            return v.data.function == "query";
          });
          if (!queryNode.checked) {
            this.$refs.tree.setChecked(queryNode.data, true, false);
          }
          let parentNodeT = curNode.parent.parent;
          let childNodesT = parentNodeT.childNodes;
          let queryNodeT = childNodesT.find((v) => {
            return v.data.function == "query";
          });
          if (!queryNodeT.checked) {
            this.$refs.tree.setChecked(queryNodeT.data, true, false);
          }
        }
      }
      let allCheckList = this.$refs.tree.getCheckedNodes(true);
      this.checkAll = this.checkArr.length === allCheckList.length;
    },
    handleSourceData(data) {
      let col = data.filter((v) => {
        if (v.function && v.function == "query") {
          this.queryArr.push(v);
        } else {
          if (v.children) {
            v.children = this.handleSourceData(v.children);
          }
          return v;
        }
      });
      return col;
    },
    getResource(deptId) {
      let obj = {};
      let url = "";
      if (deptId == "1") {
        // 全部菜单
        url = "/acb/2.0/bossManagerRole/getPortal";
      } else {
        obj = {
          deptId: deptId,
        };
        // 已配置菜单
        url = "/acb/2.0/deptManagerResource/resources";
      }
      // 获取信息
      this.$axios
        .get(url, {
          data: obj,
        })
        .then((res) => {
          const list = [];
          const arr = [];
          const checkboxArr = [];
          const result = res.value;
          for (var k in result) {
            list.push(k);
          }
          console.log("result-->", result);
          if (result.CHARGING_BUSINESS) {
            list.forEach((index, item) => {
              const i = index;
              if (index == "PARKING_BUSINESS") {
                index = "路内停车管理系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PARKING_SYSTEM") {
                index = "路外停车管理系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "CHARGING_BUSINESS") {
                index = "新能源充电系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PARKING_GUIDANCE") {
                index = "停车诱导";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "DATA_ANALYSIS") {
                index = "数据分析与预测";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "TENANT_MANAGE") {
                index = "商户管理系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PRODUCT_OPERATION") {
                index = "运营中心";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "FINANCE_CENTER") {
                index = "财务管理";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "SYSTEM_MANAGEMENT") {
                index = "系统管理";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PARKING_FILING") {
                index = "车场备案";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              }
              checkboxArr.push(result[i]);
            });
          } else {
            list.forEach((index, item) => {
              const i = index;
              if (index == "PARKING_BUSINESS") {
                index = "路内停车管理系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PARKING_SYSTEM") {
                index = "路外停车管理系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PARKING_GUIDANCE") {
                index = "停车诱导";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "DATA_ANALYSIS") {
                index = "数据分析与预测";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "TENANT_MANAGE") {
                index = "商户管理系统";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PRODUCT_OPERATION") {
                index = "运营中心";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "FINANCE_CENTER") {
                index = "财务管理";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "SYSTEM_MANAGEMENT") {
                index = "系统管理";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              } else if (index == "PARKING_FILING") {
                index = "车场备案";
                arr.push({
                  desc: index,
                  code: i,
                  children: result[i],
                });
              }
              checkboxArr.push(result[i]);
            });
          }

          this.sourceData = arr;
          this.getAllLength(checkboxArr);
        });
    },
    getCheckedKeys() {
      let treeNodes = this.$refs.tree.getCheckedNodes();
    },
    save() {
      // 获取已选择的权限
      let allId = this.$refs.tree.getCheckedNodes(true);
      const elecResourceIdsList = [];
      const resourceIdsList = [];
      allId.forEach((item, index) => {
        // item.serverUri == 'acc' ? (elecResourceIdsList.push(item.code)) : (resourceIdsList.push(item.code));
        if (item.serverUri == "acc") {
          elecResourceIdsList.push(item.code);
        } else {
          resourceIdsList.push(item.code);
        }
      });
      this.formInline.resourceIds = resourceIdsList.join(",");
      this.formInline.elecResourceIds = elecResourceIdsList.join(",");

      // this.formInline.resourceIds = this.$refs.tree
      //   .getCheckedKeys(true)
      //   .join(",");
      if (this.formInline.resourceIds == "" && this.formInline.elecResourceIds == "") {
        this.$alert("必须选择权限", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      let url = this.isEdit ? "/acb/2.0/bossManagerRole/update" : "/acb/2.0/bossManagerRole/add";
      if (this.isEdit) {
        this.formInline = {
          bossManagerRoleId: this.$route.query.bossManagerRoleId,
          elecRoleId: this.elecRoleId,
          ...this.formInline,
        };
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          if (this.formInline.deptId == "") {
            this.$alert("请选择所属部门！！", this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
            return;
          }
          this.$axios
            .post(url, {
              data: this.formInline,
            })
            .then((res) => {
              if (res.state == 0) {
                this.$router.go(-1);
              }
            });
        }
      });
    },
    // 查找一个节点的所有父节点
    familyTree(arr1, id) {
      var temp = [];
      var forFn = function (arr, id) {
        for (var i = 0; i < arr.length; i++) {
          var item = arr[i];
          if (item.deptId === id) {
            temp.push(item);
            forFn(arr1, item.parentId);
            break;
          } else {
            if (item.childNodes) {
              forFn(item.childNodes, id);
            }
          }
        }
      };
      forFn(arr1, id);
      return temp;
    },
    getRoleDetail() {
      this.$axios
        .get("/acb/2.0/bossManagerRole/getbyid/" + this.$route.query.bossManagerRoleId)
        .then((res) => {
          let data = res.value;
          // 如果编辑旧数据----通过deptId查权限菜单
          if (!data.deptId) {
            this.getResource("1");
          } else {
            this.formInline.deptId = data.deptId; // '1708414990894501893';
            console.log(this.deptTree, "deptTree");
            // this.familyTree(this.deptTree, data.deptId)
            let deptArr = [];
            const prentArr = this.familyTree(this.treeData, data.deptId);
            prentArr.forEach((item) => {
              console.log(item, "jjjj");
              deptArr.unshift(item.deptId);
            });
            this.formInline.deptIdArr = deptArr;
            console.log(this.formInline.deptIdArr, "deptIdArr");
            setTimeout(() => {
              this.returnLevel(this.formInline.deptId, this.deptTree);
            }, 100);
          }

          this.formInline.bossManagerRoleName = data.bossManagerRoleName;
          this.formInline.description = data.description;
          this.elecRoleId = data.elecRoleId;
          let resourceIds = [];
          let elecResourceIds = [];
          if (data.resourceIds) {
            resourceIds = data.resourceIds.split(",");
          }
          if (data.elecResourceIds) {
            elecResourceIds = data.elecResourceIds.split(",");
          }
          const arrid = resourceIds.concat(elecResourceIds);
          this.$refs.tree.setCheckedKeys(arrid);
          setTimeout(() => {
            this.checkAll = this.checkArr.length === arrid.length;
          }, 1000);
        });
    },
    getAllLength(arr) {
      // arr [[{},{}],[{},{}]]
      arr.forEach((item, i) => {
        item.forEach((childItem, j) => {
          if (childItem.children) {
            this.findChild(childItem.children);
          } else {
            this.checkArr.push(childItem.code);
          }
        });
      });
    },
    findChild(list) {
      list.forEach((item, i) => {
        if (item.children) {
          this.findChild(item.children);
        } else if (item.type == "2" || !item.children) {
          this.checkArr.push(item.code);
        }
      });
    },
    // 递归取一、二级用户
    returnLevel(deptId, arr) {
      arr.forEach((ele1) => {
        if (ele1.deptId == deptId && ele1.level == 1) {
          this.getResource(this.formInline.deptId);
        } else if (ele1.deptId == deptId && ele1.level == 2) {
          this.getResource(this.formInline.deptId);
        } else if (ele1.deptId == deptId && ele1.level > 2) {
          this.returnLevel22(ele1.parentId, this.deptTree);
        } else if (ele1.childNodes) {
          this.returnLevel(deptId, ele1.childNodes);
        }
      });
    },
    // 递归通过三、四、、、取对应二级
    returnLevel22(parentId, arr) {
      arr.forEach((ele1) => {
        if (ele1.deptId == parentId && ele1.level == 2) {
          this.getResource(ele1.deptId);
        } else if (ele1.deptId == parentId && ele1.level > 2) {
          this.returnLevel22(ele1.parentId, this.deptTree);
        } else if (ele1.childNodes) {
          this.returnLevel22(parentId, ele1.childNodes);
        }
      });
    },
  },

  components: {
    // TitleModule
  },
  created() {
    this.getDeptTree();
    // this.getResource();
    // if (this.$route.query.bossManagerRoleId) {
    //   this.isEdit = true;
    //   this.getRoleDetail();
    // }
    // 获取所属部门
    this.getByCurrentUser();
  },
  mounted() {
    // if (this.$route.query.bossManagerRoleId) {
    //   this.isEdit = true;
    //   this.getRoleDetail();
    // }
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!-- <style scoped lang="stylus" rel="stylesheet/stylus">-->
<style scoped>
.breadcrumb {
  height: 35px;
}
.content {
  background: #ffffff;
  overflow: hidden;
  border: 1px solid #c0ccda;
  border-radius: 4px;
  padding: 20px;
}
.el-tree {
  padding-left: 20px;
  color: #1f2d3d;
}
>>> .el-tree > .el-tree-node > .el-tree-node__content:first-child {
  padding: 4px 0;
  margin-bottom: 10px;
  background-color: #f4f7fa;
}
/* >>>.el-icon-caret-right:before{
  content: "";
} */
.all {
  line-height: 50px;
  background: #f4f7fa;
  padding-left: 20px;
  margin-bottom: 20px;
}
.titleLeft {
  color: #1f2d3d;
  font-size: 16px;
  font-weight: 600;
  margin-right: 28px;
}
.roleName {
  margin-bottom: 20px;
  color: gray;
}

.el-col-offset-6 {
  margin: 0;
}

.el-col-6 {
  float: none;
}
</style>
