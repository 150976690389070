var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c("div", { staticClass: "content" }, [
      _c("h2", { staticClass: "title" }, [
        _c("div", { staticClass: "title_icon" }),
        _vm._v(" " + _vm._s(_vm.isEdit ? "修改" : "添加") + "角色信息 "),
      ]),
      _c(
        "div",
        { staticClass: "content-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticStyle: { width: "500px" },
              attrs: {
                "label-position": "right",
                model: _vm.formInline,
                rules: _vm.rules,
                "label-width": "100px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Role_Name"),
                    prop: "bossManagerRoleName",
                  },
                },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formInline.bossManagerRoleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "bossManagerRoleName", $$v)
                      },
                      expression: "formInline.bossManagerRoleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("searchModule.remarks") } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.formInline.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "description", $$v)
                      },
                      expression: "formInline.description",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("searchModule.Department_to"),
                    prop: "deptId",
                  },
                },
                [
                  _c("el-cascader", {
                    ref: "deptIdCascader",
                    attrs: {
                      options: _vm.treeData,
                      "expand-trigger": "hover",
                      filterable: "",
                      clearable: "",
                      "show-all-levels": false,
                      props: _vm.defaultPropsCascader,
                      "change-on-select": "",
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.formInline.deptIdArr,
                      callback: function ($$v) {
                        _vm.$set(_vm.formInline, "deptIdArr", $$v)
                      },
                      expression: "formInline.deptIdArr",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.formInline.deptId,
                  expression: "formInline.deptId",
                },
              ],
              staticClass: "all",
            },
            [
              _c("span", { staticClass: "titleLeft", attrs: { name: "" } }, [
                _vm._v("权限分配"),
              ]),
              _c(
                "el-checkbox",
                {
                  on: { change: _vm.handleCheckAllChange },
                  model: {
                    value: _vm.checkAll,
                    callback: function ($$v) {
                      _vm.checkAll = $$v
                    },
                    expression: "checkAll",
                  },
                },
                [_vm._v("全选")]
              ),
            ],
            1
          ),
          _c("el-tree", {
            ref: "tree",
            attrs: {
              "empty-text": _vm.emptyText,
              data: _vm.sourceData,
              props: _vm.treeProp,
              "show-checkbox": "",
              accordion: "",
              "node-key": "code",
            },
            on: { check: _vm.nodeClickHandle },
          }),
          _c(
            "div",
            { staticStyle: { "margin-top": "20px", "text-align": "center" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$router.go(-1)
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }